export const constantsEn = {
    dearMoms: "Dear Moms,",
    motherhoodMessage: "Motherhood is a blessing, filled with moments of joy and wonder. Along with it comes the challenges of breastfeeding and the uncertainties of nipple confusion. With Pigeon as your trusted companion, feeding time transforms into a precious opportunity for bonding with your lil’ one.",
    introducingBottle: "Introducing Pigeon's New Wide Neck SofTouch Bottle, specially designed to give babies the most comfortable and natural feeding experience. With over 60 years of breastfeeding research, trust Pigeon for smooth feeding sessions, transforming them into treasured moments like no other.",
    naturalSwitch: "Natural Switch between Breast & Bottle",
    softFlexibleNipple: "SOFT & FLEXIBLE NIPPLE",
    antiColicSystem: "ANTI-COLIC SYSTEM",
    antiColicSystemDescription: "Advanced venting system provides smooth milk flow, preventing baby from swallowing air.",
    exploreBottle:"Explore",
    exploreFullRangeBottle: "the full range",
    explore:"Explore",
    exploreFullRange: "SofTouch Wide Neck Nipples",
    buyNow: "Buy Now",
    supportBreastfeeding: "Created to Support Breastfeeding",
    researchTitle: "Ever wondered how babies suck?",
    latchingOn: "Latching On",
    latchingOnDescription: "The lips open outward and latch onto the areola.",
    sucking: "Sucking",
    suckingDescription: '"Wave-like" tongue movement squeezes the nipple and extracts milk.',
    swallowing: "Swallowing",
    swallowingDescription: "The back of the tongue rises, channeling the milk into the esophagus.",
    studyTitle: "A Study on Latch-on Line",
    studyDescription: 'Pigeon conducts extensive research and combines the outcomes to design nursing bottles. The motion of a baby attaching onto the mother\'s nipple is called "latch-on". The study measured the depth of a good latch in babies who latch stably. And derived that there is a certain position for babies to latch on from the breast or artificial nipple. Derived from this study, the latch-on line is a new feature of the Pigeon Wide Neck SoftTouch Bottle added as a guidance for a good latch.',
    learnMore: "Learn more about New SofTouch Wide Neck 3 Nursing Bottle",
    latchDesc:"Guides mother and baby to appropriate latching position.",
    softestDesc:" Softest amongst all brands, as if drinking naturally from mother's breast.",
    description1:"Backed by extensive research on",
    description2:"baby's sucking behaviour since 1949",
    latch:"LATCH-ON LINE",

  };
  
  export const constantsAr = {
    dearMoms: "مرحبًا",
    motherhoodMessage: "الأمومة هي نعمة كبيرة مفعمة باللحظات السعيدة والمميزة، لكن قد تواجهين خلالها تحديات متعلقة بالرضاعة الطبيعية. مع بيجون الرفيق الموثوق، يتحول وقت الرضاعة إلى فرصة ثمينة للتواصل مع صغيركِ.",
    introducingBottle: "تعرفي على رضّاعة بيجون الجديدة سوفت تاتش ذات العنق العريض، المصممة خصيصًا لتوفير تجربة رضاعة مريحة وطبيعية لطفلكِ. بفضل أكثر من 60 عامًا من البحث والدراسات في مجال الرضاعة، يمكنكِ الوثوق ببيجون لجعل جلسات الرضاعة سلسة وتحويلها إلى لحظات ثمينة وفريدة.",
    naturalSwitch: "انتقال سلس من الرضاعة الطبيعية إلى الرضّاعة",
    softFlexibleNipple: "الحلمة الاكثر نعومة ومرونة",
    antiColicSystem: "نظام مضاد للمغص",
    antiColicSystemDescription: ".نظام تهوية متطور يضمن تدفق الحليب بسلاسة، ويمنع دخول الهواء إلى معدة الطفل",
    exploreBottle:"Explore",
    exploreFullRangeBottle: "the full range",
    explore:"الحلمة",
    exploreFullRange: "الاكثر نعومة ومرونة",
    buyNow: "تسوّقي الآن",
    supportBreastfeeding: "مصممة لدعم الرضاعة الطبيعية",
    researchTitle: "هل سألتِ نفسكِ يومًا كيف يرضع الأطفال؟",
    latchingOn: "الإمساك بالحلمة (الإلتقام)",
    latchingOnDescription: "فتح الشفتين للخارج للالتصاق بالهالة",
    sucking: "عملية المص",
    suckingDescription: 'حركة لسان الطفل تكون على شكل "موجة" تضغط على الحلمة وتخرج الحليب.',
    swallowing: " البلع",
    swallowingDescription: " يرتفع الجزء الخلفي من اللسان لنقل الحليب إلى المريء.",
    studyTitle: 'دراسة حول "الإمساك بالحلمة"',
    studyDescription: 'تقوم بيجون بإجراء أبحاث مكثفة وتستخدم نتائجها لتصميم الرضّاعات. عملية الامساك بالحلمة من قِبَل الرضيع تُعرف بـ "التقاط الحلمة". في هذه الدراسة، تم قياس عمق التمسك الجيد عند الأطفال الذين يلتصقون بالحلمة. ووجدنا أن هناك وضعًا معينًا يجب أن يتخذه الطفل للتمسك بالحلمة بشكل صحيح، سواء كانت حلمة الثدي الطبيعية أو حلمة الرضّاعة.استنادًا إلى هذه الدراسة، تم إضافة ميزة خط "التمسك بالحلمة" إلى رضّاعة بيجون سوفت تاتش ذات العنق العريض، كإرشاد لضمان تمسك الطفل بشكل صحيح أثناء الرضاعة.',
    learnMore: "تعرّفي أكثر حول رضّاعة سوفت تاتش ذات العنق الواسع 3 من بيجون",
    latchDesc:"إرشادات للأمهات لاعتماد طريقة الرضاعة المناسبة",
    softestDesc:"أكثر نعومة من جميع العلامات التجارية لتوفير تجربة تحاكي الرضاعة الطبيعية",
    description1:"مدعومة بأبحاث ودراسات معمّقة منذ 1949 ",
    description2:"حول طريقة رضاعة الطفل ",
    latch:"تجربة الرضاعة الأفضل على الإطلاق"
  };
  